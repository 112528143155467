.STL_query_container {
  color: black;
  .STL_query_inner_container {
    height: 80vh;
    position: relative;
  }

  .STL_query_inner_inner_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 1rem;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 1rem;
  }

  h3 {
    margin: 0 !important;
    padding: 0 !important;
  }

  p,
  h1,
  h2 {
    margin: 0;
    padding: 0;
  }

  button {
    font-size: 1.5rem;
    background-color: transparent;
    border: 2px solid rgba(0, 0, 0, 0.5);
    padding: 1rem 4rem;
    border-radius: 4px;
    width: fit-content;
  }

  input {
    background-color: transparent;
    border: 0px solid black;
    border-bottom: 1px solid black;
    height: 2rem;
    border-radius: 0;
  }

  label {
    display: flex;
    flex-direction: column;
  }

  .mup {
    margin-top: 4rem;
  }

  .status {
    font-weight: 300;
    font-size: 2rem;
    margin-top: 1rem;
  }

  .success {
    color: #2c6e38;
  }

  .failure {
    color: #ff696a;
  }

  .back_button {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    padding: 0;
    border: 0;

    background-color: transparent;

    img {
      width: 2.5rem;
    }
  }

  .entry {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    height: 20rem;
    background-color: #f9ddc8;
    padding: 2rem;
    border-radius: 12px;

    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);

    h3 {
      font-size: 2rem;
      font-weight: 300;
    }
  }

  .entry.is_loading {
    height: auto;
  }

  .loading_style {
    color: black;
    height: auto;
    padding: 0 0 2rem 0;

    h1 {
      color: black;
    }

    .MuiCircularProgress-root {
      margin-top: 1rem;
    }

    svg,
    .colorPrimary {
      fill: black;
      color: black;
    }
  }
}
