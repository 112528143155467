.online_intake_container {
  z-index: 10;
  position: relative;

  .online_intake_startlike_background {
    position: absolute;
    z-index: -1;
    object-fit: cover;
    height: 100%;
    width: 100%;
    top: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  label,
  span,
  a,
  button,
  input,
  textarea,
  select,
  option {
    color: black;
    font-weight: 200;
    margin: 0rem;
  }

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.75rem;
  }
  h3 {
    font-size: 2.5rem;
  }
  h4 {
    font-size: 2.25rem;
  }
  h5 {
    font-size: 2rem;
  }
  h6 {
    font-size: 1.75rem;
  }

  p {
    font-size: 1.125rem;
    font-weight: 600;
  }

  label {
    font-size: 1rem;
    font-weight: 600;
  }

  button {
    font-size: 1.5rem;
    background-color: transparent;
    border: 2px solid rgba(0, 0, 0, 0.5);
    padding: 1rem 4rem;
    border-radius: 4px;
    width: fit-content;
  }

  input {
    background-color: transparent;
    border: 0px solid black;
    border-bottom: 1px solid black;
    height: 2rem;
    border-radius: 0;
  }

  .front_container {
    background-color: rgba(220, 234, 252, 0.68);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  }
}

.online_intake_container.online_intake_container_desktop {
  width: 100%;
  height: 110vh;
  display: flex;

  .content_container {
    margin: auto 2rem auto auto;
    overflow-y: scroll;
    @media screen and (max-width: 1280px) {
      margin-right: 0.75rem;
    }
    @media screen and (max-width: 960px) {
      margin-right: 0.5rem;
    }
  }
  .progression_container {
    margin: auto auto auto 2rem;
    @media screen and (max-width: 1280px) {
      margin-left: 0.75rem;
    }
    @media screen and (max-width: 960px) {
      margin-left: 0.5rem;
    }
  }

  .content_container,
  .progression_container {
    @media screen and (min-width: 1920px) {
      width: 30.34676007rem;
      height: 38rem;
    }

    width: 24.34676007rem;
    height: 32rem;
    border-radius: 14px;
    background-color: rgba(220, 234, 252, 0.68);
    display: flex;
    flex-direction: column;
    padding: 1rem;

    @media screen and (max-width: 960px) {
      width: 18rem;
      height: 23.8004385966rem;
    }
  }

  .progression_nav_button_container {
    width: 100%;
    display: flex;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 20px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000;
  }
}

.online_intake_container.online_intake_container_mobile.start_step {
  height: 110vh;
  width: 100%;
  display: flex;
  overflow: hidden;

  .front_container {
    width: 90%;
    height: 80%;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  h1,
  p,
  button {
    text-align: center;
  }
  p,
  button {
    margin: 1rem auto 0rem auto;
  }

  .start_mobile_step_view_container {
    display: flex;
    flex-direction: column;
  }
}

:root {
  --mobile_front_container_padding_x: 1em;
  --mobile_front_container_padding_y: 0.5em;
}

.online_intake_container.online_intake_container_mobile.non_start_step {
  display: flex;
  height: 100vh;
  width: 100%;
  position: fixed;
  flex-direction: column;
  z-index: 1101;
  top: 0;
  font-size: 16px;

  .front_container {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 80%;
    justify-self: center;
    margin: auto;
    padding: var(--mobile_front_container_padding_y)
      var(--mobile_front_container_padding_x);
  }

  .step_title {
    font-size: 0.9em;
    margin: auto 0.5rem auto auto;
    font-weight: 200;
  }

  .upper_context_row {
    display: flex;
  }

  .back_button {
    width: 1.5em;
    margin: 0em 0em 0em auto;
    position: absolute;
    z-index: 1103;
  }
  .back_button:hover {
    cursor: pointer;
  }
  .step_title:hover {
    cursor: pointer;
  }
  .info_button:hover {
    cursor: pointer;
  }
  .info_button {
    width: 1.5em;
    margin: 0em auto 0em 0em;
  }

  .lower_context_row {
    width: 80%;
    border-radius: 1000px;
    height: 1em;
    margin: 0.5em auto 0em auto;
    background-color: #b3c3d8;
    overflow: hidden;
  }

  .progress_made {
    height: 100%;
    border-radius: 1000px;
    display: flex;
  }

  .input_pair {
    display: flex;
    flex-direction: column;
  }

  input::placeholder {
    color: #6b6b6b; /* 6B6B6B */
  }

  .input_type,
  .infinite_input_type {
    label {
      font-size: 2em;
      font-weight: 200;
    }
  }

  .infinite_input_type {
    display: flex;
    flex-direction: column;
  }

  .infinite_input_type .input_pair {
    margin: 0.5em 0em 0em 1em;
  }

  .infinite_input_type .add_button,
  .infinite_input_type .remove_button {
    height: 3rem;
    padding: 0;

    margin: 0em;
    margin-top: 1rem;
  }

  .infinite_input_type .add_button {
    width: 8rem;
  }

  .infinite_input_type .remove_button {
    width: 12rem;
  }

  .lower_mobile_nav_buttons {
    display: flex;
    height: 7rem;

    button {
      height: 4.5rem;
      width: 8.5rem;
      padding: 0;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    button:nth-of-type(1) {
      margin: auto auto 0 0;
    }

    button:nth-of-type(2) {
      margin: auto 0 0 auto;
    }
  }

  .mobile_up_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overscroll-behavior: contain;
    padding: 1rem 1rem 5rem 1rem;
    margin: 1rem 0;
  }

  .title_container {
    display: flex;
    margin: 0 auto 0rem auto;

    .info_button {
      margin: auto 0em auto 0.75rem;
    }
  }

  .mobile_progression_container {
    flex-direction: column;
    width: 100%;
    height: 100%;
    overscroll-behavior: contain;
    margin: 1.5rem 0;
    padding: 0rem 2rem 6rem 1rem;
    overflow-y: scroll;

    .step_container {
      width: 100%;
      height: calc(8.5rem - 10px);
      display: flex;
      flex-direction: column;
      background: rgba(220, 234, 252, 0.68);
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      padding: 1rem;
      text-align: center;
      position: relative;
      filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));
      transition: background-color 0.2s ease-in-out;
    }

    .step_container:hover {
      cursor: pointer;
      background-color: rgba(220, 234, 252, 0.884);
    }

    .progress_container {
      display: flex;
      width: 100%;
      height: 10px;
      background-color: #b3c3d8;
      margin-top: 1rem;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;

      section {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }
    }

    h2 {
      font-size: 1.5em;
      margin: auto;
    }

    .text_progress {
      position: absolute;
      right: 1rem;
      top: 0.35rem;
      display: flex;

      img {
        width: 1rem;
        height: 1rem;
        margin: 0.165rem 0.25rem auto auto;
      }

      label {
        font-size: 1em;
        font-weight: 200;
      }
    }
  }

  .selection_container {
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 2.5rem;
    }

    label {
      font-weight: 200;
      font-size: 1.25rem;
      display: flex;
      width: 100%;
      height: auto;
      border-radius: 12px;
      background-color: #dae2ed;
      padding: 0.65rem;
      margin-top: 1.375rem;
      transition: background-color 0.2s ease-in-out;
    }

    label.clicked {
      background-color: #83aeea;

      .real_radio {
        background-color: #0969ee;
        display: flex;
        padding: 0.18rem;

        img {
          transform: translateY(0.04rem);
          width: 100%;
          height: 100%;
        }
      }
    }

    .label_text {
      /* margin: auto 0 auto 1rem; */
      display: flex;
      overflow: hidden;
      width: fit-content;
    }

    label:hover {
      cursor: pointer;
    }

    .fake_custom_radio {
      display: none;
    }
    .real_radio {
      content: "";
      display: inline-block;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      border: 2px solid #0969ee;
      margin: auto 1rem auto 0;
      transition: background-color 0.2s ease-in-out;
    }
    .real_radio.selected {
      background-color: #0969ee;
    }
  }

  .calendly-schedule {
    margin-top: 2rem;
  }
}
