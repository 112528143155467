.horizontal-rule-container.light-mode {
   background-color: #f9ddc8 !important;
   hr {
      border-color: #9a9a9a !important;
   }
}

.horizontal-rule-container {
   display: flex;
   width: 100%;
   margin: 0rem;
   background-color: black;
   hr {
      border-color: white;
      padding: 0rem;
      margin: 0rem;
      width: 100%;
      position: static;
   }
}
