.standard_form_1 {
  color: black;

  .fixed_inner_container {
    height: 80%;
  }

  .reimbursement_inner_container {
    display: flex;
    flex-direction: column;
    height: auto;
    margin: auto;
    overflow-y: auto;
    width: 100%;
    padding: 0 2rem 0 0;
  }

  h3 {
    margin: 0 !important;
  }

  label {
    margin-top: 2rem;
  }
  input {
    margin-top: 0.5rem;
  }

  button.add_disbursement {
    margin-top: 0.5rem;
  }

  .remove_disbursement {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  button.submit {
    margin-top: 2rem;
    margin-bottom: 5rem;
  }

  .disbursement_container {
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    padding: 1rem;
    margin-top: 0.5rem;
    width: 100%;

    input {
      width: 100%;
    }
  }

  h2 {
    margin: 1rem 0 0 0;
  }
}

.standard_form_1.mobile {
  .fixed_inner_container {
    padding: 1rem;
  }

  .reimbursement_inner_container {
    padding: 0 0.5rem 0 0;
  }
}
