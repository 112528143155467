.STL_query_container {
  .intake {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    height: 20rem;
    background-color: #f9ddc8;
    padding: 2rem;
    border-radius: 12px;

    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);

    h3 {
      font-size: 2rem;
      font-weight: 300;
    }
  }

  .intake.is_loading {
    height: auto;
  }
}
