.subsplash-giving-container {
  height: 930px;
  background-color: white;
}

@media screen and (max-width: 960px) {
  .subsplash-giving-container {
    height: 1020px;
  }
}
