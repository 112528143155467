.loading-screen-container {
	width: 100%;
	height: 100vh;
	display: flex;

	.inner-container {
		width: auto;
		height: auto;
		margin: auto;
		display: flex;
		flex-direction: column;
	}
	.loading-text {
		color: white;
		text-align: center;
	}
	.loading-item {
		width: 10vw !important;
		height: auto !important;
		margin-left: auto;
		margin-right: auto;
	}
	@media screen and (max-width: 960px) {
		.loading-item {
			width: 20vw !important;
			height: auto !important;
		}
	}
	@media screen and (max-width: 600px) {
		.loading-item {
			width: 30vw !important;
			height: auto !important;
		}
	}
}
