.decision-section-container {
   display: flex;
   width: 100%;
   height: 120vh;
   @media screen and (max-width: 600px) {
      height: auto;
      padding: 0rem 0rem 3rem;
   }
   .first-inner-section-container {
      margin: auto;
      overflow: hidden;
   }
   .decision-container {
      @media screen and (max-width: 600px) {
         margin-top: 3rem;
      }
      .inner-decition-container {
         padding: 1rem;
         display: flex;
         flex-direction: column;
      }
      img {
         object-fit: cover;
         position: absolute;
         top: 0;
         z-index: 1;
         width: 100%;
         height: 100%;
      }
      .text {
         margin-top: 1rem;
      }
      .decision-title {
         transition: all 0.5s ease;
      }
      .hover-content-expand,
      .hover-content-shrink {
         height: auto;
         max-height: 0;
         overflow-y: hidden;
         -webkit-transition: max-height 0.5s ease-in-out;
         -moz-transition: max-height 0.5s ease-in-out;
         -o-transition: max-height 0.5s ease-in-out;
         transition: max-height 0.5s ease-in-out;
         display: flex;
         flex-direction: column;
      }

      .hover-content-expand {
         height: auto !important;
         max-height: 70vh;
      }
   }
}
