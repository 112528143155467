.rich-text-container {
   display: flex;
   flex-direction: column;
   img {
      max-width: 100%;
      margin-bottom: 1rem;
      width: auto;
      height: auto;

      .mx-auto {
         margin-left: auto;
         margin-right: auto;
      }
   }
   .space {
      width: 100%;
      height: 1rem;
   }
   .center {
      text-align: center;
   }
   .left {
      text-align: left;
   }
   .right {
      text-align: right;
   }
}

.light-mode {
   .rich-text-container {
      color: black;
   }
}
