.custom-dropdown {
  display: inline-block;

  .see_options {
    right: 0;
    position: absolute;
  }

  .selected-value {
    display: block;
    padding: 12px 20px;
    border: 0;
    border-bottom: 1px solid black;
    cursor: pointer;
    position: relative;
  }

  .dropdown-options {
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: transparent;
    width: 100%;
  }

  .dropdown-options li {
    padding: 12px 20px;
    background-color: transparent;
    cursor: pointer;
    border: 1px solid black;
    margin: 0.5rem;
    border-radius: 4px;
  }
}
