.stlh2 {
   width: 100%;
   @media screen and (min-width: 0px) {
   }
   @media screen and (max-width: 600px) {
      font-size: 2.75rem !important;
   }
   @media screen and (min-width: 960px) {
   }
   @media screen and (min-width: 1280px) {
   }
   @media screen and (min-width: 1920px) {
   }
}
