.comments-container {
	background-color: black;
	color: white !important;
	border-color: white;
	padding-top: 2rem;
	padding-bottom: 2rem;

	.comments-inner-container {
		display: flex;
		flex-direction: column;
	}
	.comments-header {
		display: flex;
		justify-content: space-between;
	}
	.comment-text-area {
		width: 100%;
		margin-top: 1rem;
		color: white;
		border-color: white;
	}
	.post-button {
		margin-left: auto;
		margin-right: auto;
		margin-top: 1rem;
	}
	.single-comment {
		display: flex;
		margin-top: 1rem;
	}
	.comment-text {
		display: flex;
		flex-direction: column;
		margin-left: 1rem;
		color: white;
		p {
			margin-bottom: 0.5rem;
		}
		.date {
			font-size: 0.7rem;
		}
	}
	.user-icon {
		width: 3rem;
		min-width: 3rem;
		height: 3rem;
		border-radius: 100%;
		background-color: white;
		display: flex;
		p {
			margin: auto;
			text-align: center;
			font-size: 1.5rem;
			color: black;
		}
	}
}
.comments-container.light-mode {
	background-color: #f9ddc8;
	color: black;
	.comment-text-area,
	h4,
	h5,
	h6,
	button,
	p {
		color: black;
	}

	.user-icon {
		background-color: black;
		p,
		.date {
			color: white;
		}
	}

	textarea {
		border-color: black;
	}
}
