.stl-form-container {
   position: relative;
   display: flex;
   flex-direction: column;
   .img-bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      z-index: 0;
   }
   .blue-film {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      z-index: 1;
      background-color: rgba(17, 112, 255, 0.5);
   }
   .outer-material-container {
      height: 100%;
      display: flex;
   }
   .center-container {
      background: rgba(255, 255, 255, 0.5);
      box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
      backdrop-filter: blur(15px);
      -webkit-backdrop-filter: blur(15px);
      border-radius: 10px;

      border-radius: 10px;

      margin: auto;
      z-index: 2;
      position: relative;
      padding: 1.5rem 3.5rem;
      width: 50%;
      border-radius: 10px;
   }
   .inner-ele {
      padding: 0.5rem 0rem 0.5rem 0.5rem;
      color: black;
      margin: 0.5rem 0rem;

      background: transparent;
   }

   .input-ele {
      border-top: 0rem;
      border-left: 0rem;
      border-right: 0rem;
      border-bottom: 1px solid black;
      padding-left: 0px !important;
      padding-right: 0px !important;
      margin: 0.5rem !important;
      margin-top: 0rem !important;
      margin-bottom: 1rem !important;
      margin-right: 0rem !important;
   }

   #message-input {
      border: 1px solid black;
      padding: 0.5rem !important;
      border-radius: 4px;
      height: 4rem;
   }
   #message-input:focus {
      border-radius: 0px;
   }
   .input-ele:focus {
      outline-style: solid;
      outline-width: 1px;
      outline-color: rgb(107, 107, 107);
      outline-offset: 0.5rem;
   }

   .inner-ele::placeholder {
      color: rgb(107, 107, 107);
   }

   .action-button {
      margin: 0.5rem !important;
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
      margin-right: 0rem !important;
      color: black;
      border: 1px solid black;
      width: 100%;
      padding: 1rem 3rem;
      border-radius: 4px;
      background-color: transparent;
   }

   @media screen and (max-width: 960px) {
      .center-container {
         width: 80%;
      }
   }

   @media screen and (max-width: 600px) {
      .center-container {
         width: 100%;
      }
   }
}
